.edit-btn{
    border-radius: 8.883px;
background: #688DD7;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
width: 110px;
height: 42px;
padding: 5px 10px;
outline: none;
border: none;

color: #FAFAFA;
font-family: 'Montserrat';
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 27px */
}

.viewuser-heading{
    color: #5D606E;
font-family: 'Montserrat';
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 33.6px */
}

.viewuser-heading2{
    color: #5D606E;
font-family: 'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
}

.avatar-div{
    width: 100%;
height: 70px;
flex-shrink: 0;
background: #688DD7;
padding-left: 40px;
}


.avatar-view-user {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #BDD3FF;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
font-family: 'Montserrat';
font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 50.914px */
  }

  .user-name{
    color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 19.633px; /* 70.116% */
margin: 0;
  }

  .user-email{
    color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 19.633px; /* 109.07% */
text-transform: lowercase;
  }

  .attribute-label{
    color: #5D606E;
font-family: 'Montserrat';
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 27px */
margin: 0;

  }


  .attribute-value{
    color: #8B8B8B;
font-family: 'Montserrat';
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
padding-top: 5px;
  }


  .user-details{
    padding-left: 40px;
  }

  .lock-txt{
    color: #EA5455;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 13.25px;
font-style: normal;
font-weight: 400;

  }

  .active-txt{
    color: #28C76F;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 13.25px;
font-style: normal;
font-weight: 400;

  }

  .lock:checked{
    background-color: #EA5455 !important;
    border: 1.36px solid #FD7879 !important;
  }

  .active-user:checked{
    background-color: #28C76F !important;
    border: 1.36px solid #45E98E !important;
  }