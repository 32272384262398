.dynamic-heading{
    color: #5D606E !important;
font-family: 'Montserrat';
font-size: 20px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 120%; /* 28.8px */
}

.dynamic-submit{
    display: flex;
width: 123px;
padding: 9px 20px;
justify-content: center;
align-items: center;
gap: 8.883px;

border-radius: 8.883px;
background: #688DD7;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);

border: none;

color: #FAFAFA;
font-family: 'Montserrat';
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */
}

.txt-area{
    display: flex;
width: 100%;
height: 200px;
padding: 13.324px;
align-items: flex-start;
flex-shrink: 0;
border-radius: 8.883px;
border: 1.11px solid #F2F2F2;
background: #FFF;
}

.txt-area:focus{
    border: 1.11px solid #F2F2F2;
    outline: 1.11px solid #F2F2F2;;
}

.dynamic-label{
    color: #818183;

font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
}


.string{
    border-radius: 8.883px;
border: 1.11px solid #F2F2F2;
background: #FFF;
padding: 13.324px;
width: 100%;
}

.string:focus{
    border: 1.11px solid #F2F2F2;
    outline: 1.11px solid #F2F2F2;;
}