.property-details{
    padding-left: 22px;
}





@media screen and (min-width: 1401px) {
    /* Styles for very large desktop screens */

    
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
    /* Styles for standard laptops/desktops */

  
}

@media screen and (max-width: 1023px) {

    
    .left-img{
      display: none;
    }
}