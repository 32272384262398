.register-heading2{color: #5D606E;
    font-family: 'Montserrat';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */}

    .input_label_style2{
        color: #5D606E;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
    }


    .register_btn{
        margin-top: 40px;
        display: flex;
    width: 129px;
    padding: 10px 10px;
    justify-content: center;
    align-items: center;
    gap: 8.883px;
    
    border-radius: 8.883px;
    background: #688DD7;
    box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
    outline: none;
    border: none;
    
    
    color: #FAFAFA;
    font-family: 'Montserrat';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 26.648px */
    }

    .register-header{
        color: #5D606E;
font-family: 'Montserrat';
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 33.6px */
    }

    .input_style_register{
        border-radius: 8.883px;
    border: 1.11px solid #D7D7D7;
    background: #FFF;
    box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
    
    display: flex;
    height: 50px;
    padding: 13.324px;
    align-items: center;
    align-self: stretch;
    width: 100%;
    }
    
    .input_style_register:focus{
        outline: none;
    }

