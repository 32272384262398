.name-square{
    width: 35px;
height: 35px;
flex-shrink: 0;
background-color: #7367F0;
display: flex;
align-items: center;
justify-content: center;

color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 17.701px; /* 80.46% */
border-radius: 5px;
}

.templatename{
    color: #807F85;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 17.701px; /* 88.506% */
padding-left: 20px;
display: flex;
align-items: center;
}

.templatename-input{
    border-radius: 8.883px;
border: 1.11px solid #D7D7D7;
background: #FFF;
box-shadow: 0px 1.11px 2.221px 0px rgba(0, 0, 0, 0.08);
display: flex;
height: 42px;
padding: 13.324px;
align-items: center;
margin-left: 20px;
flex-shrink: 0;
}

.templatename-input:focus{
    outline: none;
}