.forgotpassword-style{
    color: #5D606E;
font-family: 'Montserrat';
font-size: 2.5vw;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 38.4px */
padding-top: 1.875rem;
}


.forgotpassword-heading2-style{
font-family: 'Montserrat';
font-size: 1.09375vw;
color: rgba(43, 43, 43, 0.50);
font-style: normal;
font-weight: 400;
line-height: 150%;
}


.forgot-password-txt-style{
    color: #5F5F5F;

font-family: 'Montserrat';
font-size: 3.125vw;
font-style: normal;
font-weight: 600;
line-height: 120%; 
}

