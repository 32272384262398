.modal-header{
    border-bottom: none !important;
}
.modal-heading{
    color: #5D606E;
font-family: 'Montserrat';
font-size: 2vw;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 33.6px */
padding-top: 10px;
}

.btn-close{
    background-color: #688DD7 !important;
    color: white !important;
    font-size: 11px !important;
    opacity: 0.8 !important;
}
.custom-modal-dialog {
.modal-body{
    padding-right: 40px !important;
}
}

@media screen and (min-width: 1401px) {
    /* Styles for very large desktop screens */
    .custom-modal-dialog {
        .modal-dialog{
            max-width: 60% !important;
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
    /* Styles for standard laptops/desktops */
    .custom-modal-dialog {
        .modal-dialog{
            max-width: 60% !important;
        }
    }
    
}

@media screen and (max-width: 1023px) {
    /* Styles for mobile screens */
    .custom-modal-dialog {
        .modal-dialog{
            max-width: 60% !important;
        }
    }
    
}