.table-header{
    background: #F3F2F7;

    color: var(--1-theme-color-heading-display-text, #5E5873);
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 11.219px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.935px;
text-transform: uppercase;
width: 100%;
}

.table-headings{
    padding: 20%;
}

.heads{
    padding: 20px;
    font-weight: 600;
}

.body-row{
    color: var(--1-theme-color-body-text, #6E6B7B);
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 19.633px; /* 163.605% */

}

.body-data{
    padding: 20px;
}

.view-btn{
    border-radius: 6px;
    background-color: #688DD7;
    // border-color: #688DD7;
    border: none;
    color: white;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 8.611px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.246px;
padding: 5px 10px;


}


.duplicate-btn{
    border-radius: 6px;
    background-color: white;
    border-color: #C9CD00;

    color: #C9CD00;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 8.611px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.246px;
padding: 5px 10px;


}


.delete-btn{
    border-radius: 6px;
    background-color: white;
    border-color: #EA5455;

    color: #EA5455;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 8.611px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.246px;
padding: 5px 10px;



}