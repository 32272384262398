.main-content {
    flex-grow: 1;
    /* More styling */
  width: 100%;
  }

  .avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(174, 139, 231);
  }

  .date{
    color: #807F85;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 17.701px; /* 126.437% */
padding-top: 10px;
  }
  

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .main-content {
    // existing styles...
    animation: fadeIn 1s ease-in-out;
  }
  .fade-in {
    animation: fadeIn 1s ease-in-out;
  }

  


















  $nav-link-color: #666666;
  $bg-color: #F3F5F4;
  $navbar-color: #FFFFFF;
  $border-gray: #E0E0E0;
  $hover-gray: #F5F5F5;
  $active-gray: #EBEBEB;
  
  $at-blue-light: #00AFE9;
  $at-blue-dark: #006AB8;
  
  $red: #F44336;
  
  
  @mixin border-radius($radius) {
    -moz-border-radius:$radius;
    -webkit-border-radius:$radius;
    -ms-border-radius:$radius;
    border-radius:$radius;
  }
  
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    background-color: #FFFFFF;
    font-family: sans-serif;
  }
  
  ul {
    list-style: none;
  }
  
  .user-menu-wrap {
    position: relative;
    width: 36px;
   
  }
  
  .menu-container {
    visibility: hidden;
    opacity: 0;
    
    &.active {
      visibility: visible;
      opacity: 1;
      transition: all .2s ease-in-out;
    }
  }
  
  .user-menu {
    position: absolute;
    right: 25px;
    background-color: #FFFFFF;
    width: 256px;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,0.15);
    padding-top: 5px;
    padding-bottom: 5px;
    top:40px;
    z-index: 9999;

    border-radius: 8.851px;
background: var(--white-white, #FFF);
box-shadow: 0px 1.77011px 4.42529px 0px rgba(38, 51, 77, 0.03);

    .profile-highlight {
      display: flex;
      border-bottom: 1px solid $border-gray;
      padding: 12px 16px;
      margin-bottom: 6px;
  
      img {
        width: 29px;
        height: 29px;
        border-radius: 25px;
        object-fit: cover;
      }
      
      .details {
        display: flex;
        flex-direction: column;
        margin: auto 12px;
        
        #profile-name {
          color: #807F85;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: 'Montserrat';
          font-size: 13.621px;
          font-style: normal;
          font-weight: 600;
          line-height: 17.701px; /* 166.667% */
          
        }
        
        #profile-footer {
          font-weight: 300;
          font-size: 14px;
          margin-top: 4px;
        }
      }
  
    }
  
    .footer {

      padding-top: 0px;
      margin-top: 6px;
  
      .user-menu-link {
        font-size: 13px;
      }
    }
  
    .user-menu-link {
      display: flex;
      text-decoration: none;
      font-weight: 400;
      padding: 12px 16px;
      align-items: center;

      color: #ABAAAB;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 17.701px; /* 177.011% */
      
      div {
        margin: auto 10px;
      }
      
      &:hover {
        background-color: $hover-gray;
        color: #333333;
      }
    }
    
    &:before {
      position: absolute;
      top: -16px;
      left: 120px;
      display: inline-block;
      content: "";
      border: 8px solid transparent;
      border-bottom-color: $border-gray;
    }
    
    &:after {
      position: absolute;
      top: -14px;
      left: 121px;
      display: inline-block;
      content: "";
      border: 7px solid transparent;
      border-bottom-color: #FFFFFF;
    }
  }

  .span-border{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: #F4F4F4;
    border-radius: 50%;
  }



  @media screen and (min-width: 1401px) {
    /* Styles for very large desktop screens */
    .menu-icon{
      display: none;
    }
    
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
    /* Styles for standard laptops/desktops */
    .menu-icon{
      display: none;
    }
  
}

@media screen and (max-width: 1023px) {
    /* Styles for mobile screens */
    .date{
        display: none;
    }
    
    .menu-icon{
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  
    
    .left-img{
      display: none;
    }
}