.checklist-view-btn{
    border-radius: 5px;
background: #0300AC;

display: inline-flex;
height: 39px;
padding: 3px 15px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border: none;


color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */

margin-top: 20px;
}

.checklist-view-btn2{
    border-radius: 5px;
background: rgba(129, 129, 131, 0.18);

display: inline-flex;
height: 39px;
padding: 3px 15px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;

color: #818183;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
border: none;
margin-right: 10px;
}

.checklist-template-btn-bg{
    background: #F6F6F6;
    width: 100%;
height: 79px;
flex-shrink: 0;

display: flex;
align-items: center;
}


.checklist-template-btn{
    border-radius: 5px;
background: #7367F0;

display: inline-flex;
height: 39px;
padding: 3px 15px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;


color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
border: none;
}

.action-txt{
    color: #818183;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */

}

.action-txt2{
    text-transform: capitalize;
}


.action-txt3{
    color: #818183;
text-align: left;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */

}

.action-txt4{
    color: #5555b1;
text-align: left;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */

}


.checkbox-style{
    border-radius: 3.331px;
border: 1.11px solid #D7D7D7;
background: #FAFAFA;
box-shadow: 0px 1.11035px 1.11035px 0px rgba(0, 0, 0, 0.06);
width: 16px;
height: 16px;
}

.indicator{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
}


.indicator3{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color:  #28C76F;;
    display: flex;
    align-items: center;
}


.indicator2{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #FFEB80;
    display: flex;
    align-items: center;
}
.elipsis-text{
    width: 140px; /* or any fixed width or max-width */
    white-space: nowrap; /* Prevents the text from wrapping */
    overflow: hidden; /* Hide the overflowed text */
    text-overflow: ellipsis; /* Add ellipsis to overflowed text */
}

.comment{
    display: inline-flex;
height: 32.766px;
padding: 5px 20px;
justify-content: center;
align-items: center;
gap: 10px;
text-overflow:ellipsis;
flex-shrink: 0;
border-radius: 5px;
background: rgba(171, 255, 208, 0.58);
border: none;
width: 125px;
color: #28C76F;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 214.286% */
}

.file{
    display: flex;

height: 32.766px;
padding: 5px 20px;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 5px;
background: rgba(48, 80, 246, 0.20);
color: #1839EB;
text-align: center;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 21px */
border: none;
width: 140px;
}


.scrollable-row {

    flex-wrap: nowrap; // Prevents items from wrapping
    overflow-x: auto;  // Enables horizontal scrolling
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
}

.scrollable-row > div {
    max-width: 100%; // Example minimum width, adjust as needed
    // min-width: 150px;
}

.lock-txt{
    color: red;
    font-size: 14px;
    display: flex;
    justify-content: end;
    padding-top: 20px;
}

@media screen and (max-width: 1023px) {

.checklist-attributes{
   flex-direction: column;
   text-align: center;
}
.checklist-view-btn2{
    margin-top: 10px;
}

.scrollable-row > div {
    max-width: 100%; // Example minimum width, adjust as needed
    min-width: 900px;
}

}