.checklist-labels{
    color: #5D606E;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
}

.checklist-input{
    border-radius: 8.883px;
border: 1.11px solid #D7D7D7;
background: #FFF;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
display: flex;
width: 100%;
height: 43px;
padding: 13.324px;
align-items: center;

font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
}

.checklist-input::placeholder{
    color: rgba(43, 43, 43, 0.26);
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
}

.checklist-input:focus{
    outline: none;
}



.checklist-select{
    border-radius: 9.454px;
    box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
    border: 1.11px solid #D7D7D7;
background: var(--white-white, #FFF);
display: flex;
width: 100%;
height: 43px;
padding: 9px 18.909px 9px 20px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;


// color: var(--gray-blue-grey-blue-80, #ADB8CC);
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 18.909px; /* 166.667% */

}


.checklist-select:focus{
    outline: none;
}

.checklist-select::placeholder{
    color: var(--gray-blue-grey-blue-80, #ADB8CC);
}

.toggle-background{
    border-radius: 8px;
border: 1.11px solid #F2F2F2;
background: #FFF;






}