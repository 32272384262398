.reset-style{
    color: #5D606E;
font-family: 'Montserrat';
font-size: 2.5vw;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 38.4px */
padding-top: 3rem;
}

.reset-heading2-style{
    color: rgba(43, 43, 43, 0.50);
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
}