.password-field {
    margin-bottom: 20px;
  }
  
  .password-field label {
    display: block;
    margin-bottom: 5px;
  }
  
  .password-field input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .password-field button {
    display: inline-block;
    padding: 10px;
    margin-left: 10px;
    background-color: #eee;
    border: 1px solid #ddd;
    cursor: pointer;
  }
  
  .reset-heading{
    
    color: #5D606E;
font-family: 'Montserrat';
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 29.224px */
  }