.dynamic-dropdown {
    width: 100%;
    margin: 0 auto;
}

.dynamic-dropdown input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.dropdown-list {
    width: 19vw;
    margin-top: 2px;
    border: 1px solid #ccc;
    border-top: none; /* Aligns with the input field */
    box-sizing: border-box;
    position: absolute;
    background-color: white;
    z-index: 9999;
}

.dropdown-option {
    padding: 8px;
    background-color: white;
    cursor: pointer;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}
