.in_progress{
    color: #F6B330;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 12.477px;
font-style: normal;
font-weight: 400;
line-height: 18.715px; /* 150% */

border-radius: 20px;
background: rgba(246, 179, 48, 0.17);
padding: 2px 5px;
white-space: nowrap;
}

.complete{
    color: #040A9B;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 12.477px;
font-style: normal;
font-weight: 400;
line-height: 18.715px; /* 150% */
border-radius: 20px;
background: rgba(4, 10, 155, 0.17);
padding: 2px 5px
}

.open{
    color: #19F2D8;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 12.477px;
font-style: normal;
font-weight: 400;
line-height: 18.715px; /* 150% */
border-radius: 20px;
background: rgba(25, 242, 216, 0.17);
padding: 2px 5px
}

.Active{
    color: #049B48;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 12.477px;
font-style: normal;
font-weight: 400;
line-height: 18.715px; /* 150% */
border-radius: 20px;
background: #D9FFEA;
padding: 2px 5px
}

.InActive{
    color: #6E6B7B;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 12.477px;
font-style: normal;
font-weight: 400;
line-height: 18.715px; /* 150% */
border-radius: 20px;
background: rgba(110, 107, 123, 0.16);
padding: 2px 5px;
white-space: nowrap;
}