.discard-btn{
    border-radius: 8.883px;
border: 1px solid #688DD7;
background: #FFF;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);

display: flex;
width: 110px;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 8.883px;

color: #688DD7;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */
}

.editsave-btn{
    border-radius: 8.883px;
background: #688DD7;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);

display: flex;
width: 140px;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 8.883px;
border: none;

color: #FAFAFA;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */

}

.resend-btn{
    border-radius: 8.883px;
background: #688DD7;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);

display: flex;
width: 140px;
height: 45px;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 8.883px;
flex-shrink: 0;

border: none;

color: #FAFAFA;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */
}