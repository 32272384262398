@import url('https://fonts.googleapis.com/css?family=Montserrat');

// .app-container{
//   min-height: 100vh;
// }

/* This styles the entire scrollbar */
::-webkit-scrollbar {
    width: 10px; /* width of the vertical scrollbar */
  }
  
  /* This styles the track (progress bar) of the scrollbar */
  ::-webkit-scrollbar-track {
    background: #f1f1f145; /* color of the tracking area */
  }
  
  /* This styles the handle (thumb) of the scrollbar */
  ::-webkit-scrollbar-thumb {
    background: #888888a2; /* color of the scroll thumb */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5555556e; /* color of the scroll thumb on hover */
  }
  