.grey-box{
    background-color: #F6F6F6;
    padding: 20px;
}

.comment-box{
    background-color: #ABFFD038;
    padding: 20px;
}

.attribute-box{
   background-color: #F0FFFF;
   padding: 20px;
}


.attachment-box{
    background-color: #3050F617;
    padding: 20px;
}

.complete-status-btn{
background-color: #0AA949;
height: 33px;
padding: 5px 20px 5px 20px;
gap: 10px;
color: #FFFFFF;
font-family: 'Montserrat';
font-weight: 600;
border-radius: 5px;
text-align: Center;
font-size: 14px;
}

.incomplete-status-btn{
    background-color: #6E6B7B;
    height: 33px;
    padding: 5px 20px 5px 20px;
    gap: 10px;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-weight: 600;
    border-radius: 5px;
    text-align: Center;
    font-size: 14px;
    }


 .grey-labels{
    font-family: 'Montserrat';
    color: #5E5873;
    font-size: 14px;
    padding-bottom: 10px;
    font-weight: 400;
 }   

 .grey-values{
    font-family: 'Montserrat';
    color: #5E5873;
    font-size: 14px;
    font-weight: 500;
 }   


 .status-label{
    background-color: #FFEB80;
    height: 26px;
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    color: #FFFFFF;
 }

 .action-item{
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #818183;
 }

 .action-header{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #28C76F;
 }

 .action-value{
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #28C76F;
 }


 .attribute-header{
   font-family: 'Montserrat';
   font-weight: 500;
   font-size: 14px;
   color: #0096FF;
}

.attribute-value{
   font-family: 'Montserrat';
   font-weight: 400;
   font-size: 14px;
   color: #0096FF;
}


 .attachment-header{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #5F668B;
 }

 .attachment-value{
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #5F668B;
    text-decoration: underline;
 }