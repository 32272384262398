.filter-box{
    border-radius: 10px;
border: 1px solid #F4F4F4;
background: #FFF;
padding: 10px;
}

.filter-select{
    border-radius: 9.454px;
border: 0.945px solid #F4F4F4;
background: var(--white-white, #FFF);
display: flex;
width: 100%;
height: 38.763px;
padding: 9px 18.909px 9px 20px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;


color: var(--gray-blue-grey-blue-80, #ADB8CC);
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 11.345px;
font-style: normal;
font-weight: 400;
line-height: 18.909px; /* 166.667% */
}
.filter-select:focus{
    outline: none;
}

.filter-label{
    color: #5E5873;
font-family: 'Montserrat';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.create-checklist-btn{
    display: inline-flex;
    height: 38px;
    padding: 3px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    border-radius: 5px;
background: #688DD7;

color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
border: none;
}


.filter-margin{
    border-top: 1px solid #F4F4F4;
    padding-top: 5px;
}

.apply-btn{
    display: inline-flex;
padding: 3px 15px;
justify-content: center;
align-items: center;
gap: 10px;

border-radius: 5px;
background: #6E6B7B;

color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
border: none;
}

.recently-viewed{
    display: inline-flex;
height: 41px;
padding: 3px 15px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;

border-radius: 5px;
background: rgba(129, 129, 131, 0.07);


color: #818183;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
}

.recent-cross{
    color: white;
    background-color: #818183;
    border-radius: 3px;
}

.overflow{
    overflow: scroll;
    
}