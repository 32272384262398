
ul{
    list-style-type: circle;
    color: #5D606E;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
padding-left: 10px !important;
}