.create-template-btn{
    border-radius: 5px;
background: #688DD7;
padding-left: 10px;
padding-right: 10px;
height: 38px;
color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */

outline: none;
border: none;
margin-top: 35px;
}

input:disabled{
opacity: 0.6;
}