.breadcrumb{
    color: #807F85;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 17.701px; /* 98.34% */
cursor: pointer;
}

.register-modal-btn{
    border-radius: 5px;
background: #688DD7;
width: 150px;
height: 38px;
color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */

outline: none;
border: none;
}

.search-input{
    border-radius: 10px;
border: 1px solid #F4F4F4;
background: var(--white-white, #FFF);
width: 340px;
height: 40px;
padding: 20px;

color: var(--gray-blue-grey-blue-80, #ADB8CC);
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Montserrat';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
}

.search-input:focus{
    outline: none;
}

@media screen and (max-width: 1023px) {

    .search-input{
        border-radius: 10px;
    border: 1px solid #F4F4F4;
    background: var(--white-white, #FFF);
    width: auto;
    height: 40px;
    padding: 20px;
    
    color: var(--gray-blue-grey-blue-80, #ADB8CC);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Montserrat';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    }
}