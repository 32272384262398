.propertysave-btn{
    border-radius: 8.883px;
background: #688DD7;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);

display: flex;
width: 180px;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 8.883px;
border: none;

color: #FAFAFA;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */

}
.PhoneInputInput {
    border: none !important;
}

.PhoneInputInput:focus {
    outline: none !important;
}



@media screen and (min-width: 1401px) {
    /* Styles for very large desktop screens */
    .hide-image{
        display: none;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
    /* Styles for standard laptops/desktops */
    .hide-image{
        display: none;
    }
    
}

@media screen and (max-width: 1023px) {
    /* Styles for mobile screens */
    .hide-image{
        display: none !important;
    }
    
}