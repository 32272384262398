

.delete-btn-confirm{
    border-radius: 8.883px;
border: 1px solid #d76868;
background: #FFF;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);

display: flex;
width: 100px;
padding: 6px 10px;
justify-content: center;
align-items: center;
gap: 8.883px;

color: #d76868;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */
}

.delete-confirmation{
    font-family: 'Montserrat';
    font-weight: 500;
    text-align: center;
}


.no-btn-confirm{
    border-radius: 8.883px;
border: 1px solid #688DD7;
background: #FFF;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);

display: flex;
width: 100px;
padding: 6px 10px;
justify-content: center;
align-items: center;
gap: 8.883px;

color: #688DD7;
font-family: 'Montserrat';
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */
}