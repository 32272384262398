

.login-container{
    padding: 3.75rem;
}



.demo{
    background-color: #688DD7;
    color: white;
    border: none;
    padding: 9px;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'Montserrat' !important;
}


.input_style:focus{
    outline: none;
}








.img-container {
    display: flex;
    justify-content: center; /* aligns horizontally */
    align-items: center; /* aligns vertically */
    height: 100%; /* Use the viewport height, or set to the height you want */
    
  }


  @media screen and (min-width: 1401px) {
    /* Styles for very large desktop screens */
    .logo-style{
        width: 100px !important;
        height: 98px;
    }

    .login-style{
        color: #5D606E;
    font-family: 'Montserrat';
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 38.4px */
    padding-top: 3rem;
    }

    .login-heading2-style{
        color: #5D606E;
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    }
    
    .input_style{
        border-radius: 8.883px;
    border: 1.11px solid #D7D7D7;
    background: #FFF;
    box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
    
    display: flex;
    height: 45.297px;
    padding: 13.324px;
    align-items: center;
    align-self: stretch;
    width: 100%;
    }

    .input_label_style{
        color: #5D606E;
    font-family: 'Montserrat';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    }
    
    
.remember_style, .remember_style a{
    color: #5D606E !important;
text-align: center;
font-family: 'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27.661px */
}

.checkbox_style{
    border-radius: 3.331px;
    border: 1.11px solid #D7D7D7;
    background: #FFF;
    box-shadow: 0px 1.11035px 1.11035px 0px rgba(0, 0, 0, 0.06);
    width: 15px;
    height: 15px;
}

.login_btn{
    margin-top: 40px;
    display: flex;
width: 100%;
padding: 15.259px 25.518px;
justify-content: center;
align-items: center;
gap: 8.883px;

border-radius: 8.883px;
background: #688DD7;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
outline: none;
border: none;


color: #FAFAFA;
font-family: 'Montserrat';
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */
}


.bottom-txt{
    color: #5D606E;
text-align: center;
font-family: 'Montserrat';
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
padding-top: 42px;
}


.welcome-style{
    padding-top: 1.875rem;

    color: #5F5F5F;
font-family: 'Montserrat';
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 48px */
  }

  .nice-to-see-style{
    color: #81869F;
font-family: 'Montserrat';
font-size:20px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 28.8px */
  }

  .img-fluida{
    max-width: 450px;
  }

.welcome-div{
    display: block;
}
  }

@media screen and (min-width: 1024px) and (max-width: 1400px) {
    /* Styles for standard laptops/desktops */

    .logo-style{
        width: 90px !important;
        height: 78px;
    }


    .login-style{
        color: #5D606E;
    font-family: 'Montserrat';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 38.4px */
    padding-top: 2rem;
    }


    .login-heading2-style{
        color: #5D606E;
    font-family: 'Montserrat';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    }
    
    .input_style{
        border-radius: 8.883px;
    border: 1.11px solid #D7D7D7;
    background: #FFF;
    box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
    
    display: flex;
    height: 41.297px;
    padding: 13.324px;
    align-items: center;
    align-self: stretch;
    width: 100%;
    }
    
    .input_label_style{
        color: #5D606E;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    }

    
.remember_style, .remember_style a{
    color: #5D606E !important;
text-align: center;
font-family: 'Montserrat';
font-size: 14.1px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27.661px */
}

.checkbox_style{
    border-radius: 3.331px;
    border: 1.11px solid #D7D7D7;
    background: #FFF;
    box-shadow: 0px 1.11035px 1.11035px 0px rgba(0, 0, 0, 0.06);
    width: 13px;
    height: 13px;
}

.login_btn{
    margin-top: 40px;
    display: flex;
width: 100%;
padding: 10.259px 20.518px;
justify-content: center;
align-items: center;
gap: 8.883px;

border-radius: 8.883px;
background: #688DD7;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
outline: none;
border: none;


color: #FAFAFA;
font-family: 'Montserrat';
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */
}

.bottom-txt{
    color: #5D606E;
text-align: center;
font-family: 'Montserrat';
font-size: 12.319px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
padding-top: 42px;
}

.welcome-style{
    padding-top: 1.875rem;

    color: #5F5F5F;
font-family: 'Montserrat';
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 48px */
  }

  .nice-to-see-style{
    color: #81869F;
font-family: 'Montserrat';
font-size:18px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 28.8px */
  }

  .img-fluida{
    max-width: 350px;
  }
  .welcome-div{
    display: block;
}

}

@media screen and (max-width: 1023px) {
    /* Styles for mobile screens */
 
    .logo-style{
        width: 80px !important;
        height: 78px;
    }

    .login-style{
        color: #5D606E;
    font-family: 'Montserrat';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 38.4px */
    padding-top: 2rem;
    }

    .login-heading2-style{
        color: #5D606E;
    font-family: 'Montserrat';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    }
    
    .input_style{
        border-radius: 8.883px;
    border: 1.11px solid #D7D7D7;
    background: #FFF;
    box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
    
    display: flex;
    height: 41.297px;
    padding: 13.324px;
    align-items: center;
    align-self: stretch;
    width: 100%;
    }
    .input_label_style{
        color: #5D606E;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    }

    
.remember_style, .remember_style a{
    color: #5D606E !important;
text-align: center;
font-family: 'Montserrat';
font-size: 13.1px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27.661px */

}

.checkbox_style{
    border-radius: 3.331px;
    border: 1.11px solid #D7D7D7;
    background: #FFF;
    box-shadow: 0px 1.11035px 1.11035px 0px rgba(0, 0, 0, 0.06);
    width: 12px;
    height: 12px;
}

.login_btn{
    margin-top: 40px;
    display: flex;
width: 100%;
padding: 10.259px 20.518px;
justify-content: center;
align-items: center;
gap: 8.883px;

border-radius: 8.883px;
background: #688DD7;
box-shadow: 0px 1.11035px 2.2207px 0px rgba(0, 0, 0, 0.08);
outline: none;
border: none;


color: #FAFAFA;
font-family: 'Montserrat';
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 26.648px */
}

.bottom-txt{
    color: #5D606E;
text-align: center;
font-family: 'Montserrat';
font-size: 12.319px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
padding-top: 42px;
}

.welcome-style{
    padding-top: 1.875rem;

    color: #5F5F5F;
font-family: 'Montserrat';
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 48px */
  }

  .nice-to-see-style{
    color: #81869F;
font-family: 'Montserrat';
font-size:18px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 28.8px */
  }

  .img-fluida{
    max-width: 350px;
  }

  .welcome-div{
    display: none;
}
}