.image-upload{
    border-radius: 8.83px;
    border: 1.11px dashed #688DD7;
background: var(--white, #FFF);
height: 240px;
cursor: pointer;
// display: flex;
padding: 16px 24px;
// flex-direction: column;
align-items: center;
gap: 4px;

display: flex;
align-items: center;
justify-content: center;
}

.image-upload > input{
    color: #2A9470;
/* Text sm/Semibold */
font-size: 14px;
font-family: Inter;
font-style: normal;
font-weight: 600;
line-height: 20px;
}

.preview_div{
    border-radius: 8px;
border: 1px solid var(--gray-200, #EAECF0);
background: var(--white, #FFF);
display: flex;
justify-content: space-between;
}

.text-area{
    border-radius: 8px;
border: 1px solid var(--gray-300, #D0D5DD);
background: var(--white, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
padding: 10px 14px;
width: 100%;
}

.upload_txt{
    color: #B8B8B8;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Montserrat';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 250% */

}

.upload_txt2{
    color: var(--gray-500, #667085);
text-align: center;
/* Text xs/Regular */
font-size: 12px;
font-family: 'poppins';
font-style: normal;
font-weight: 400;
line-height: 18px;
}

.file_name{
    color: var(--gray-700, #344054);
/* Text sm/Medium */
font-size: 14px;
font-family: 'poppins';
font-style: normal;
font-weight: 500;
line-height: 20px;
}